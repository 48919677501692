.about-wrap {
  padding: 5rem 10rem 3rem 10rem;
}
.about {
  background-color: white;
  margin-top: 100px;
  box-shadow: 10px 5px 40px rgba(0,0,0,.45);
}
.ciel-blurb {
  padding: 50px;
  font-size: 1.3em;
	color: #333;
}
.center {
  text-align: center;
}
.about-page-title {
  font-size: 3rem;
}

.about-wrap a {
  text-transform: uppercase;
  font-family: "helvetica", sans-serif;
  text-decoration: none;
  color: gray;
}
hr {
  width: 75%;
}

@media screen and (max-width: 1080px) {
  .about-wrap {
    padding: 5rem 0rem 3rem 0rem;
  }
  .ciel-blurb {
    padding: 50px 10px 50px 10px;
  }
}
